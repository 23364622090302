<template>
  <div class="h-100 w-100" style="position: relative">
    <div
      style="min-height: 100px; border: 2px dotted #7367f0"
      class="rounded w-100 d-flex flex-column justify-content-center align-items-center p-50"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center my-1"
        style="height: 60px"
      >
        <feather-icon icon="UploadCloudIcon" size="24" />
        <input
          type="file"
          :multiple="allowMultiple"
          :name="uploadFieldName"
          :disabled="isDisabled"
          @change="
            handleFileChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          :accept="acceptedFileTypes"
          class="input-file"
        />
        <p
          class="drag-area-text py-0"
          v-if="isInitial && selectedFiles == null"
        >
          {{ initialText }}
        </p>
        <p class="drag-area-text py-0" v-else>Files Selected</p>
      </div>
      <div
        class="d-flex flex-row flex-wrap align-items-center justify-content-center"
        style="z-index: 99; bottom: 0"
      >
        <template v-if="selectedFiles && selectedFiles != null">
          <b-badge
            :key="file.lastModified"
            v-for="file in selectedFiles"
            variant="light-primary"
            class="mb-25 mx-25"
            >{{ file.name
            }}<feather-icon
              v-if="allowFileRemoval"
              @click="handleRemoveClick(file)"
              icon="XIcon"
              class="ml-25 text-dark cursor-pointer"
          /></b-badge>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge, BButton, BSpinner } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins.js";
import FeatherIcon from "../@core/components/feather-icon/FeatherIcon.vue";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  components: {
    BBadge,
    BButton,
    BSpinner,
    FeatherIcon,
  },
  mixins: [ResponseMixins],
  data() {
    return {
      isLoading: false,
      uploadError: null,
      currentStatus: 0,
      selectedFiles: null,
    };
  },

  props: {
    uploadFieldName: {
      type: String,
      required: true,
    },
    initialText: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    acceptedFileTypes: {
      type: String,
      required: true,
    },
    allowMultiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowFileRemoval: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeAction: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    filteredList() {
      return this.controls.control_families.filter((cf) => {
        return cf.name.toLowerCase().includes(this.searchcontrol.toLowerCase());
      });
    },
  },

  methods: {
    handleFileChange(fieldName, fileList) {
      console.log("Selected Files", fileList);
      if (!fileList.length) return;
      this.selectedFiles = fileList;
      this.$emit("files-selected", fileList);
      // this.save(fileList);
    },

    handleRemoveClick(file) {
      const isCustomAction = this.removeAction();
      if (isCustomAction) {
      } else {
        const newSelectedFiles = [];
        this.selectedFiles.forEach((f) => {
          if (f.name != file.name && f.lastModified != file.lastModified) {
            newSelectedFiles.push(file);
          }
        });
        this.selectedFiles = newSelectedFiles;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.drag-area-text {
  font-weight: 400;
  text-align: center;
  padding: 16px 0;
}
</style>
