<template>
  <div>
    <b-modal ref="modal-import" title="Upload Registers" hide-footer>
      <div class="d-flex flex-column align-items-start">
        <b-form-file
          v-model="selectedFile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class="mb-25"
          accept=".csv"
        />

        <div class="d-flex align-items-center justify-content-start mt-1">
          <b-button
            size="sm"
            variant="success"
            @click="handleFileUploadSubmit"
            :disabled="showOverlay || !selectedFile || selectedFile === null"
            ><feather-icon icon="CheckIcon" size="15" class="mr-25" />
            <span class="align-middle">Submit</span></b-button
          >

          <b-button
            class="ml-50"
            size="sm"
            variant="primary"
            @click="handleDownloadButtonClick"
            ><feather-icon icon="DownloadIcon" class="mr-25" /><span
              class="align-middle"
              >Download Sample File</span
            ></b-button
          >
        </div>
      </div>
    </b-modal>
    <div class="card bg-card p-1">
      <div class="row mb-1">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center">
            <input
              v-model="searchPeriodic"
              placeholder="Search"
              class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
              style="max-width: 200px"
            />
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                class="ml-50"
                size="sm"
                variant="success"
                @click="handleImportClick"
                ><feather-icon icon="UploadIcon" size="15" class="mr-25" />
                <span class="align-middle">Upload</span></b-button
              >
              <b-button
                class="ml-50"
                size="sm"
                variant="success"
                @click="
                  () => {
                    this.$router.push('/legalregister-uploads');
                  }
                "
                ><feather-icon icon="BookIcon" size="15" class="mr-25" />
                <span class="align-middle">View Upload History</span></b-button
              >
              <button
                class="btn btn-outline-primary float-right btn-sm mt-1 mb-1 ml-50"
                @click="openAddLegal"
                :disabled="
                  !this.$store.state.auth.permissions.includes(
                    'periodic_activity-create'
                  )
                "
              >
                + Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card p-2">
          <div class="row">
            <div class="col-md-12">
              <table class="table border rounded">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Legal Register No.</th>
                    <th scope="col">Title</th>
                    <th scope="col">Country</th>
                    <th scope="col">Type</th>
                    <th scope="col">Regulatory</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(l, index) in this.legalregisters" :key="l._id">
                    <td scope="row">
                      {{ (currentPage - 1) * perPage + index + 1 }}
                    </td>
                    <td>{{ l.legal_register_number }}</td>
                    <td>{{ l.title }}</td>
                    <td>
                      {{ l.country == null ? "Global" : l.country.name }}
                    </td>
                    <td>
                      {{ l.type }}
                    </td>
                    <td>
                      <!-- {{l.authority == null ? '' :l.authority}} -->
                      {{l.regulatory&&l.regulatory.name? l.regulatory.name:''}}
                    </td>
                    <td class="align-center flex-display">
                      <span
                        v-b-toggle.edit-activity
                        @click="EditLegalRegister(l._id)"
                        class="icon-box-new"
                      >
                        <feather-icon
                          class="pointer rotate"
                          icon="Edit2Icon"
                          size="20"
                        />
                      </span>
                      <span
                        @click="openDeleteModel(l._id)"
                        class="icon-box-new"
                      >
                        <feather-icon
                          class="text-danger pointer rotate"
                          icon="Trash2Icon"
                          size="20"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-1 mr-1">
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  :total-rows="lastPage * perPage"
                  class="float-right"
                />
              </div>
            </div>
          </div>
        </div>
        <b-sidebar
          id="add-activity"
          bg-variant="white"
          right
          backdrop
          shadow
          width="640px"
          v-model="add_activity"
          lazy
        >
          <div class="p-2">
            <validation-observer ref="simpleRules">
              <b-form-group label="Activity" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name=" Title"
                  rules="required"
                >
                  <label for="">Title</label>
                  <b-form-input
                    id="basicInput"
                    placeholder="Enter Title"
                    v-model="Legalregistertitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <label for="" class="mt-1">Requirement</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                class="form-control"
                placeholder="Enter Requirement"
                v-model="requirement"
              ></textarea>
              <b-form-group
                label="Requirement URL"
                label-for="requirement_url"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Requirement Url"
                >
                  <b-form-input
                    vid="requirement_url"
                    v-model="requirement_url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Reference Files"
                label-for="reference_files"
                class="mt-1"
              >
                <custom-file-input
                  @files-selected="handleFilesSelected"
                  uploadFieldName="Reference Files"
                  initialText="Select or Drag files here.."
                  :allowMultiple="true"
                />
              </b-form-group>
              <label for="">Region</label>
              <v-select class="mb-1" :options="countries" :reduce="opt => opt._id" label="name" v-model="country" @search="handleCountrySearch"></v-select>

              <label for="" class="mt-1">Industry</label>
              <v-select class="mb-1" :options="industries" :reduce="opt => opt._id" label="name" v-model="industry" @search="handleIndustrySearch"></v-select>

              <label for="" class="mt-1">Type</label>
              <select name="" id="" class="form-control" v-model="type">
                <option value="" disabled selected>Select a Type</option>
                <option value="legal">Legal</option>
                <option value="regulatory">Regulatory</option>
                <option value="contractual">Contractual</option>
              </select>

              <template v-if="type == 'regulatory' || type == 'legal'">
                <label for="" class="mt-1">Regulatory</label>
                <select name="" id="" class="form-control mt-1" v-model="regulatory_id">
              <option value="" disabled selected >Select a Regulatory</option>
              <option :value="autho._id" v-for="autho in regulators" :key="autho._id">{{autho.name}}</option>
            </select>
                <!-- <input type="text" class="form-control" v-model="authority" /> -->
              </template>

              <button
                class="btn btn-primary mt-1 mr-1"
                @click.prevent="validationForm"
              >
                Add
              </button>
              <!-- <button
                class="btn btn-secondary mt-1"
                @click="cancelAddactivity()"
              >
                Cancel
              </button> -->
            </validation-observer>
          </div>
        </b-sidebar>
        <b-sidebar
          id="edit-activity"
          bg-variant="white"
          right
          backdrop
          shadow
          width="640px"
          lazy
          v-model="edit_activity"
        >
          <!-- {{edit_activity}} -->
          <div class="p-2">
            <validation-observer ref="simpleRules">
              <b-form-group label="Activity" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <label for="">Title</label>
                  <b-form-input
                    id="basicInput"
                    placeholder="Enter Title"
                    v-model="Legalregistertitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <label for="" class="mt-1">Requirement</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                class="form-control"
                placeholder="Enter Requirement"
                v-model="requirement"
              ></textarea>
              <b-form-group
                label="Requirement URL"
                label-for="requirement_url"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Requirement Url"
                >
                  <b-form-input
                    vid="requirement_url"
                    v-model="requirement_url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                class="border-light rounded p-75 d-flex flex-column align-items-start justify-content-start w-100 h-auto"
              >
                <b-form-group class="w-100">
                  <div
                    class="d-flex flex-row align-items-center justify-content-between"
                  >
                    <label>Reference Files</label>
                  </div>
                  <custom-file-input
                    @files-selected="handleFilesSelected"
                    uploadFieldName="Reference Files"
                    initialText="Select or Drag files here to upload reference files."
                    :allowMultiple="true"
                  />
                </b-form-group>

                <div
                  class="d-flex flex-row align-items-center justify-content-between"
                >
                  <label>Uploads</label>
                </div>

                <template
                  v-if="
                    uploadedFiles &&
                    uploadedFiles != null &&
                    uploadedFiles.length > 0
                  "
                >
                  <div class="table-responsive" style="max-height: 28vh">
                    <table class="table border rounded">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">File Name</th>
                          <th scope="col" class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(file, index) in uploadedFiles"
                          :key="file._id"
                        >
                          <td scope="row">
                            {{ index + 1 }}
                          </td>
                          <td scope="row">
                            {{ file.original_file_name }}
                          </td>
                          <td scope="row">
                            <div
                              class="d-flex align-items-center justify-content-center"
                            >
                              <b-button
                                @click="handleRemoveClick(file._id)"
                                variant="flat-danger"
                                class="btn-icon"
                                ><feather-icon icon="TrashIcon"
                              /></b-button>

                              <b-button
                                @click="handleDownloadClick(file)"
                                variant="flat-primary"
                                class="btn-icon"
                                ><feather-icon icon="DownloadIcon"
                              /></b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>

                <template v-else>
                  <div
                    class="w-100 h-auto d-flex flex-column p-1 align-items-center justify-content-center bg-light rounded"
                  >
                    <feather-icon
                      icon="SlashIcon"
                      class="mb-50 text-secondary"
                      size="28"
                    />
                    <p class="mb-0">No Uploads.</p>
                  </div>
                </template>
              </div>

              <label for="" class="mt-1">Region</label>
              <v-select class="mb-1" :options="countries" :reduce="opt => opt._id" label="name" v-model="country" @search="handleCountrySearch"></v-select>
              <label for="" class="mt-1">Industry</label>
              <!-- <select name="" id="" class="form-control" v-model="industry">
                <option value="" disabled selected>Select a Industry</option>
                <option
                  :value="indus._id"
                  v-for="indus in industries"
                  :key="indus._id"
                >
                  {{ indus.name }}
                </option>
              </select> -->
              <v-select class="mb-1" :options="industries" :reduce="opt => opt._id" label="name" v-model="industry" @search="handleIndustrySearch">
            <!-- <option v-for="(option,index) in industryOptions" :value="option.value" :key="index">{{ option.text }}</option> -->
            </v-select>
              <label for="" class="mt-1">Type</label>
              <select name="" id="" class="form-control" v-model="type">
                <option value="" disabled selected>Select a Type</option>
                <option value="legal">Legal</option>
                <option value="regulatory">Regulatory</option>
                <option value="contractual">Contractual</option>
              </select>
              <template v-if="type == 'regulatory' || type == 'legal'">
                <label for="" class="mt-1">Regulatory</label>
                <select name="" id="" class="form-control mt-1" v-model="regulatory_id">
              <option value="" disabled selected >Select a Regulatory</option>
              <option :value="autho._id" v-for="autho in regulators" :key="autho._id">{{autho.name}}</option>
            </select>
                <!-- <input type="text" class="form-control" v-model="authority" /> -->
              </template>

              <button
                class="btn btn-primary mt-1 mr-1"
                @click.prevent="validationFormUpdate"
              >
                Update
              </button>
              <!-- <button
                class="btn btn-secondary mt-1"
                @click="cancelAddactivity()"
              >
                Cancel
              </button> -->
            </validation-observer>
          </div>
        </b-sidebar>
        <b-modal
          ref="modal-delete-activity"
          title="Are you sure to delete? "
          hide-footer
        >
          <button
            class="btn btn-danger"
            @click="deleteActivity(actid)"
            :disabled="
              !this.$store.state.auth.permissions.includes(
                'periodic_activity-delete'
              )
            "
          >
            Delete Legal Register
          </button>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton,
  BSidebar,
  VBToggle,
  BFormInput,
  BPagination,
  BModal,
  BFormFile,
  BBadge,
  BOverlay,
  BFormGroup,
  BCollapse,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ResponseMixins from "../../mixins/ResponseMixins";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
import CustomFileInput from "../../components/CustomFileInput.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import VSelect from "vue-select";
export default {
  mixins: [ResponseMixins],
  data() {
    return {
      showOverlay: false,
      selectedFile: null,
      activity: "",
      description: "",
      activities: [],
      edit_activity: false,
      activity_id: "",
      add_activity: false,
      actid: "",
      required,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      rows: 1,
      searchPeriodic: "",
      frequency: "",
      countries: [],
      industries: [],
      authorities: [],
      Legalregistertitle: "",
      requirement: "",
      country: "",
      industry: "",
      requirement_url: "",
      reference_files: [],
      uploadedFiles: [],
      type: "",
      authority: "",
      legalregisters: [],
      legalregister_id: "",
      selectedId: null,
      regulators:[],
      regulatory_id:null,
    };
  },
  components: {
    BButton,
    BSidebar,
    VBToggle,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BModal,
    BFormFile,
    FeatherIcon,
    BBadge,
    BOverlay,
    BFormGroup,
    CustomFileInput,
    BCollapse,
    AppCollapse,
    AppCollapseItem,
    VSelect
  },
  directives: {
    "b-toggle": VBToggle,
  },
  watch: {
    currentPage(value) {
      // this.getActivitylist(value);
      this.getLegalRegisters(value);
    },
    searchPeriodic() {
      this.getLegalRegisters(this.currentPage);
    },
    country(){
      this.fetchRegulatories()
    },
    industry(){
      this.fetchRegulatories()
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getLegalRegisters(this.currentPage);
      this.getCountries();
      this.getIndustries();
      this.fetchRegulatories()
    },
    handleCountrySearch(industry){
      this.getCountries(industry)
    },
    handleIndustrySearch(industry){
      this.getIndustries(industry)
    },
    handleDownloadClick(file) {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_ONECSF_API_URL}/file-uploads/${file._id}/download`,
        // headers: {
        //   responseType: "blob",
        // },
      };
      this.$http(options)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.original_file_name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    resetNewRegisterData() {
      this.Legalregistertitle = "";
      this.requirement = "";
      this.industry = "";
      this.country = "";
      this.type = "";
      this.regulatory_id = "";
      this.requirement_url = "";
      this.reference_files = [];
      this.uploadedFiles = [];
      this.sele;
    },

    handleRemoveClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to remove this file.", {
          title: "Warning",
          size: "sm",
          headerBgVariant: "light-warning",
          okVariant: "danger",
          okTitle: "Remove",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const formData = new FormData();
            formData.append("reference_file_id", id);
            const options = {
              method: "POST",
              data: formData,
              url:
                process.env.VUE_APP_ONECSF_API_URL +
                `/legal-registers/${this.selectedId}/remove-evidence-file`,
            };
            this.$http(options)
              .then((res) => {
                console.log(res);
                this.handleResponse(res);
                this.EditLegalRegister(this.selectedId);
              })
              .catch((err) => {
                this.handleError(err);
              });
          }
        });
    },

    handleFilesSelected(files) {
      console.log("Selected Files", files);
      this.reference_files = files;
    },

    handleDownloadButtonClick() {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_ONECSF_API_URL}/legal-registers/import-csv/sample-download`,
      };
      this.$http(options)
        .then((res) => {
          var blob = new Blob([res.data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, "sample.csv");
          } else {
            var a = window.document.createElement("a");

            a.href = window.URL.createObjectURL(blob, {
              type: "text/plain",
            });
            a.download = "sample.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((err) => console.log(err));
    },
    handleFileUploadSubmit() {
      this.showOverlay = true;
      const formData = new FormData();
      formData.append("legal_register_list_csv", this.selectedFile);
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/legal-registers/import-csv`,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
          this.load();
          this.hideImportModal();
        })
        .catch((err) => {
          if (
            err.data &&
            err.data.errors &&
            err.data.errors != null &&
            err.data.errors != []
          ) {
            this.showToast(
              "Error: The uploaded file contains invalid data. Please upload a valid file.",
              "danger"
            );
          } else {
            this.handleError(err);
          }
          this.selectedFile = null;
          this.hideImportModal();
          console.log("Error:", err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleImportClick() {
      this.selectedFile = null;
      this.showImportModal();
    },

    showImportModal() {
      this.$refs["modal-import"].show();
    },
    hideImportModal() {
      this.$refs["modal-import"].hide();
    },

    openAddLegal() {
      this.add_activity = true;
      this.Legalregistertitle = "";
      this.requirement = "";
      this.industry = "";
      this.country = "";
      this.type = "";
      this.regulatory_id = "";
      this.requirement_url = "";
      this.reference_files = [];
    },

    addLegalRegister() {
      const formData = new FormData();
      formData.append("title", this.Legalregistertitle);
      formData.append("requirement", this.requirement);
      formData.append("industry_id", this.industry);
      formData.append("country_id", this.country);
      formData.append("type", this.type);
      formData.append("regulatory_id", this.regulatory_id);
      formData.append("requirement_url", this.requirement_url);
      if (this.reference_files && this.reference_files.length > 0) {
        this.reference_files.forEach((file, i) => {
          formData.append(`reference_files[${i}]`, file);
        });
      }

      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.add_activity = false;
          this.getLegalRegisters();
          this.Legalregistertitle = "";
          this.requirement = "";
          this.industry = "";
          this.country = "";
          this.type = "";
          this.regulatory_id = "";

          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data.data.data);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.activity[0],
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err.data.data.activity[0]);
        });
    },
    getCountries(text) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/countries`,
        params:{
          search:text
        }
      };
      this.$http(options)
        .then((res) => {
          this.countries = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIndustries(text) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/industries`,
        params:{
          search:text
        }
      };
      this.$http(options)
        .then((res) => {
          this.industries = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    ViewActivity(id) {
      this.$router.push({ name: "periodicdetails", params: { id: id } });
    },
    cancelbtnUpdate() {
      this.edit_activity = false;
    },
    cancelAddactivity() {
      this.add_activity = false;
      this.activity = "";
      this.description = "";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.addLegalRegister();
        }
      });
    },
    validationFormUpdate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.updateLegalRegister();
        }
      });
    },

    openDeleteModel(legalid) {
      this.actid = legalid;
      this.$refs["modal-delete-activity"].show();
    },
    deleteActivity(actid) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/legal-registers/${actid}`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.$refs["modal-delete-activity"].hide();
          this.getLegalRegisters();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateLegalRegister() {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("title", this.Legalregistertitle);
      formData.append("requirement", this.requirement);
      if (
        this.industry &&
        typeof this.industry != "undefined" &&
        this.industry !== null
      ) {
        formData.append("industry_id", this.industry);
      }

      if (
        this.country &&
        typeof this.country != "undefined" &&
        this.country !== null
      ) {
        formData.append("country_id", this.country);
      }
      if (this.type && typeof this.type != "undefined" && this.type !== null) {
        formData.append("type", this.type);
      }
      if (
        this.regulatory_id &&
        typeof this.regulatory_id != "undefined" &&
        this.regulatory_id !== null
      ) {
        formData.append("regulatory_id", this.regulatory_id);
      }
      formData.append("requirement_url", this.requirement_url);
      if (this.reference_files && this.reference_files.length > 0) {
        this.reference_files.forEach((file, i) => {
          formData.append(`reference_files[${i}]`, file);
        });
      }
      // const data = {
      //   title: this.Legalregistertitle,
      //   requirement: this.requirement,
      //   industry_id: this.industry,
      //   country_id: this.country,
      //   type: this.type,
      //   authority: this.authority,

      // };
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/legal-registers/${this.legalregister_id}`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.edit_activity = false;
          this.getLegalRegisters();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    EditLegalRegister(legalregisterid) {
      this.resetNewRegisterData();
      this.legalregister_id = legalregisterid;
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/legal-registers/${legalregisterid}`,
      };
      this.$http(options)
        .then((res) => {
          this.Legalregistertitle = res.data.data.title;
          this.requirement = res.data.data.requirement;
          this.country = res.data.data.country_id;
          this.industry = res.data.data.industry_id;
          this.type = res.data.data.type;
          this.requirement_url = res.data.data.requirement_url;
          this.regulatory_id = res.data.data.regulatory_id;
          this.uploadedFiles = res.data.data.reference_files;
          this.selectedId = legalregisterid;
          this.reference_files = [];
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalRegisters(num) {
      let newParams = {};
      if (
        this.searchPeriodic &&
        this.searchPeriodic !== null &&
        this.searchPeriodic != ""
      ) {
        newParams["search"] = this.searchPeriodic;
      }
      newParams["page"] = num;
      newParams["limit"] = this.perPage;
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/legal-registers`,
        params: newParams,
      };
      this.$http(options)
        .then((res) => {
          this.legalregisters = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;
          this.rows = this.lastPage * 10;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchRegulatories(){
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/regulatories`,
        params:{country_id:this.country,industry_id:this.industry,dont_paginate:1},
      };
      const regulatorres=await this.$http(options)
      this.regulators=regulatorres.data.data
    }
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.icon-box-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
